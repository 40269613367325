import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/HeroImage"

const AGBPage = () => (
  <Layout>
    <SEO title="AGB" />
    <HeroImage />
    <div className="container mt-3">
      <h1>AGB</h1>
      <p className="bodytext">
        <b>§ 1 Geltungsbereich</b>
      </p>
      <ol>
        <li>
          <span>
            Die nachstehenden Geschäftsbedingungen gelten ausschließlich.
            Entgegenstehende oder abweichende Bedingungen des Kunden sind nur
            dann verbindlich, wenn das Schulungszentrum für Spritzgießtechnik
            diese schriftlich anerkennt. Die Bedingungen des Schulungszentrums
            für Spritzgießtechnik gelten auch dann, wenn die Schulung in
            Kenntnis entgegenstehender oder abweichender Bedingungen des Kunden
            durchgeführt wird.
          </span>
        </li>
        <li>
          <span>
            Unsere Geschäftsbedingungen gelten auch für alle zukünftigen
            Geschäfte/Schulungen mit dem Kunden.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 2 Anmeldungsverfahren</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Eine verbindliche Anmeldung ist nur in schriftlicher Form, d.h.
            E-Mail, Brief oder über das Anmeldeformular der Webseite möglich.
          </span>
        </li>
        <li>
          <span>
            Für die Berücksichtigung der Anmeldung zählt die Reihenfolge des
            Eingangs. Sind alle Plätze der gebuchten Schulung belegt, erhalten
            sie vom Schulungszentrum für Spritzgießtechnik einen Vorschlag über
            einen neuen Termin. Eine Buchung an einem anderen Schulungstermin
            kann auf Wunsch vorgenommen werden.
          </span>
        </li>
        <li>
          <span>
            Jede Anmeldung oder Buchung ist erst dann verbindlich, wenn Sie von
            uns eine schriftliche Bestätigung per E-Mail oder Brief erhalten
            haben.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 3 Zahlungsbedingungen</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Die Teilnahmegebühren für die Kurse können dem Online Angebot
            entnommen werden, sämtliche Preise sind Nettopreise zzgl. des
            jeweiligen landesspezifisch gültigen Umsatzsteuersatzes. Der
            verbindliche Endpreis für etwaig verabredete Sonderleistungen ergibt
            sich aus der Anmeldungsbestätigung, bzw. Rechnung. Kosten für
            Prüfungen und Lehrmaterial sind in den aufgeführten Gebühren bereits
            enthalten, sofern nicht anderweitig darauf hingewiesen wird.
          </span>
        </li>
        <li>
          <span>
            Der Schulungsteilnehmer erhält zur Bezahlung sämtlicher Entgelte
            eine Rechnung. Die Rechnungsstellung erfolgt zum Zeitpunkt der
            Seminarbestätigung. Die Teilnahmegebühr ist nach Erhalt der Rechnung
            innerhalb von 10 Tagen nach Rechnungsstellung zu entrichten –
            ansonsten gilt der Schulungsplatz als nicht gesichert.&nbsp;Sie ist
            unter Angabe der Rechnungsnummer auf das in der Rechnung angegebene
            Konto zu überweisen.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 4 Stornierung und Umbuchung</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Kostenlose Stornierungen und Umbuchungen können bis maximal 4 Wochen
            vor Kursbeginn erfolgen. Bei Rücktritt bis 2 Wochen vor
            Schulungsbeginn berechnet das Schulungszentrum für Spritzgießtechnik
            50% des Teilnahmepreises als Administrationsgebühr, danach ist der
            volle Teilnahmepreis zu zahlen.
          </span>
        </li>
        <li>
          <span>
            Bei Verhinderung des ursprünglich angemeldeten Teilnehmers kann nach
            Absprache auch eine Ersatzperson benannt werden.
          </span>
        </li>
        <li>
          <span>
            Das Schulungszentrum für Spritzgießtechnik behält es sich vor, bei
            unzureichender Teilnehmerzahl, Krankheit, höherer Gewalt u.ä.
            angekündigte Schulungsveranstaltungen abzusagen. Sollten
            Alternativtermine für die Teilnehmer nicht wahrnehmbar sind, werden
            bereits beglichene Kursgebühren in voller Höhe erstattet.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 5 Haftungsbeschränkungen</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Die Teilnahme erfolgt auf eigene Gefahr. Dies gilt auch für
            Vorgespräche und Wegeunfälle.
          </span>
        </li>
        <li>
          <span>
            Das Schulungszentrum für Spritzgießtechnik übernimmt für Schäden,
            die durch seine gesetzlichen Vertreter, Erfüllungsgehilfen oder
            Angestellten verursacht werden, keine Haftung, außer es handelt sich
            um Vorsatz oder grobe Fahrlässigkeit.
          </span>
        </li>
        <li>
          <span>
            Bei Ausfall einer Schulung aufgrund Krankheit des Trainers, zu
            geringer Teilnehmerzahl, höhere Gewalt, sowie durch vom
            Schulungszentrum für Spritzgießtechnik nicht zu vertretende sonstige
            Gründe, besteht kein Anspruch auf Durchführung der Schulung. Das
            Schulungszentrum für Spritzgießtechnik kann in diesem Fall nicht zum
            Ersatz von Reise- oder Hotelkosten sowie Arbeitsausfall verpflichtet
            werden.
          </span>
        </li>
        <li>
          <span>
            Das Schulungszentrum für Spritzgießtechnik haftet nur beschränkt für
            vorhersehbare, vertragstypische Schäden, falls dieser fährlässig
            verursacht wurde.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 6 Urheber- und Schutzrechte</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Das Schulungszentrum für Spritzgießtechnik behält sich an sämtlichen
            Teilnehmerunterlagen, Schulungsunterlagen und sonstigen
            Schulungsmaterialien, körperlicher oder nichtkörperlicher Art, auch
            in elektronischer Form, Eigentums- und Urheberrechte uneingeschränkt
            vor. Der Schulungsteilnehmer ist nicht berechtigt, derartige
            Unterlagen, ganz oder auszugsweise, ohne schriftliche Genehmigung
            des Schulungszentrum für Spritzgießtechnik, zu verarbeiten,
            vervielfältigen, verbreiten oder öffentlich wiederzu-geben.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 7 Speicherung von personenbezogenen Daten</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Zur Erfüllung der vertraglich vereinbarten Dienstleistung ist es
            notwendig, dass kundenspezifische Daten beim Schulungszentrum für
            Spritzgießtechnik gesichtet, bearbei-tet und gespeichert werden (§33
            BDSG). Diese Daten unterliegen dem Datenschutz und werden nicht an
            Dritte weitergegeben. Eine Ausnahme von der „Nicht-Weiterleitung“
            der Daten kann nur durch gesetzliche Gegebenheiten oder durch
            behördliche Aufforderungen erfolgen.
          </span>
        </li>
        <li>
          <span>
            Weiterhin versichert das Schulungszentrum für Spritzgießtechnik
            ausdrücklich, dass weder Postadressen, Telefonnummern noch
            E-Mail-Adressen zu gewerblichen Zwecken weitergegeben werden.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 8 Sicherheitsinformationen bei der E-Mail-Nutzung</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Der E-Mail-Verkehr wird über die Standardprotokolle Pop3/SMTP bzw.
            IMAP4 abgewickelt. Hinweis: Bei der Nutzung von E-Mail ist darauf zu
            achten, dass die Nachrichten ungesichert und nicht verschlüsselt
            über das Internet laufen.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 9 Datengeheimnis</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Die Mitarbeiter des Schulungszentrums für Spritzgießtechnik
            verpflichten sich auf die Einhaltung des Datengeheimnisses.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 10 Erfüllungsort, Gerichtsstand</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Erfüllungsort für alle Verpflichtungen aus dem Vertragsverhältnis
            ist Regensburg.
          </span>
        </li>
        <li>
          <span>
            Gerichtsstand für alle sich aus dem Vertragsverhältnis ergebenden
            Streitigkeiten ist, wenn es sich bei dem Besteller um einen
            Kaufmann, eine juristische Person des öffentlichen Rechts oder ein
            öffentlich rechtliches Sondervermögen handelt, Regensburg. Es steht
            dem Schulungszentrum für Spritzgießtechnik jedoch frei, das für den
            Sitz des Kunden zuständige Gericht anzurufen.
          </span>
        </li>
        <li>
          <span>
            Es gilt ausschließlich deutsches Recht. Dieses ist auch dann
            anwendbar, wenn das deutsche Recht die Anwendbarkeit ausländischen
            Rechts vorsieht. Die Anwendung des UN-Übereinkommens über den
            internationalen Warenkauf vom 11.04.1980 ist ausgeschlossen.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 11 Schriftform</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Vertragliche Änderungen, Ergänzungen, und Aufhebungen, sowie
            sonstige Nebenabsprachen bedürfen zu ihrer Gültigkeit der
            Schriftform.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>
          <b>§ 12 Schlussbestimmung</b>
        </span>
      </p>
      <ol>
        <li>
          <span>
            Sollten einzelne der vorstehenden Bestimmungen unwirksam sein oder
            werden, so berührt dies nicht die Wirksamkeit der übrigen
            Bestimmungen. Anstelle der unwirksamen Bedingung sollen solche
            Regelungen treten, die dem wirtschaftlichen Zweck des Vertrages und
            der angemessenen Wahrung der beiderseitigen wirtschaftlichen
            Interessen am nächsten kommen.
          </span>
        </li>
      </ol>
      <p className="bodytext">
        <span>Stand: September 2020</span>
      </p>
    </div>
  </Layout>
)

export default AGBPage
